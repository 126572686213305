import $ from "jquery";
import * as pbi from "powerbi-client";

export default class EmbeddedReport {
  report;
  company;
  activePage;
  userId;
  #keyAccountColumns = [
    "KeyAccountName",
    "KeyAccountLevel1",
    "KeyAccountLevel2",
    "KeyAccountLevel3",
  ];

  constructor(element) {
    this.element = element;
    this.company = $(element).data("company");
    this.userId = $(element).data("user-id");
    this.assignedKeyAccountName = $(element).data("assigned-key-account-name");
    this.assignedKeyAccountLevel = $(element).data(
      "assigned-key-account-level"
    );
    this.keyAccountNameLevel1 = $(element).data("key-account-name-level-1");
    this.keyAccountNameLevel2 = $(element).data("key-account-name-level-2");
    this.keyAccountNameLevel3 = $(element).data("key-account-name-level-3");
  }

  load() {
    const models = pbi.models;
    const config = {
      type: "report",
      tokenType: models.TokenType.Embed,
      accessToken: $(this.element).data("access-token"),
      embedUrl: $(this.element).data("embed-url"),
      id: $(this.element).data("report-id"),
      permissions: models.Permissions.Read,
      viewMode: models.ViewMode.View,
      pageName: $(this.element).data("page-name"),
      settings: {
        // Enable this setting to remove gray shoulders from embedded report
        // background: models.BackgroundType.Transparent,
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth,
        },
      },
    };

    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );

    this.report = powerbi.load(this.element, config);
    this.report.on("loaded", this.#onLoaded);
    this.report.on("rendered", this.#onRendered);

    window.addEventListener("resize", () => this.#setHeight(this.activePage));
  }

  #setHeight(activePage) {
    if (activePage) {
      const defaultWidth = activePage.defaultSize.width;
      const defaultHeight = activePage.defaultSize.height;
      const currentWidthPercent = this.element.offsetWidth / defaultWidth;

      this.element.style.height =
        defaultHeight * currentWidthPercent + 18 + "px";
    }
  }

  #onLoaded = async () => {
    this.activePage = await this.report.getActivePage();
    const slicers = await this.activePage.getSlicers();

    for await (const slicer of slicers) {
      let state = await slicer.getSlicerState();
      let savedState = null;
      let savedItem = sessionStorage.getItem(this.#getSlicerStateKey(slicer, state));

      if (savedItem) {
        savedState = JSON.parse(savedItem);
      }

      if (savedState) {
        state = savedState;
      } else {
        state.targets
          .filter((target) => this.#keyAccountColumns.includes(target.column))
          .forEach((target) => {
            let filter = state.filters.find(
              (f) => f.target.column === target.column
            );

            if (!filter) {
              filter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: target.table,
                  column: target.column,
                },
                requireSingleSelection: false,
                filterType: 1,
              };
              state.filters.push(filter);
            }

            filter.operator = "In";
            filter.values = [];

            switch (filter.target.column) {
              case "KeyAccountName":
              case "KeyAccountLevel1":
                if (this.keyAccountNameLevel1) {
                  filter.values = [this.keyAccountNameLevel1];
                }
                break;
              case "KeyAccountLevel2":
                if (this.keyAccountNameLevel2) {
                  filter.values = [this.keyAccountNameLevel2];
                }
                break;
              case "KeyAccountLevel3":
                if (this.keyAccountNameLevel3) {
                  filter.values = [this.keyAccountNameLevel3];
                }
                break;
              default:
                break;
            }

            if (!filter.values.length) {
              state.filters = state.filters.filter(
                (f) => f.target.column !== filter.target.column
              );
            }
          });
      }
      await slicer.setSlicerState(state);
    }

    this.#setHeight(this.activePage);

    this.report.render();
  };

  #onRendered = async () => {
    const slicers = await this.activePage.getSlicers();
    for await (const slicer of slicers) {
      const state = await slicer.getSlicerState();
      sessionStorage.setItem(
        this.#getSlicerStateKey(slicer, state),
        JSON.stringify(state)
      );
    }
  };

  #getSlicerStateKey(slicer, state) {
    const target = state.targets[0];
    return `reportSlicerState_${this.userId}_${this.report.getId()}_${
      target.table
    }_${target.column}v4`;
  }
}
