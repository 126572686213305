import $ from 'jquery';

export default class Modal {
  constructor(opts = {}) {
    const defaultOpts = {
      content: '',
      image: '',
      onClose: () => { },
    };

    this.opts = Object.assign({}, defaultOpts, opts);
    this.$body = $('body');
    this.$modal = $(`
      <div class="modal">
        <div class="modal__image">
          <img class="product-tile__image" src="${this.opts.image}" alt="Product tile image" />
        </div>
        <div class="modal__content wysiwyg">${this.opts.content}</div>
        <div class="modal__icon modal-deactivate">
          <i class="far fa-times modal-deactivate"></i>
        </div>
      </div>
    `);

    this.$backdrop = $('.site-backdrop');
    this.$backdrop.on('click', this.close.bind(this));
    $(document).on('click', '.modal-deactivate', this.close.bind(this));
  }

  setContent(content) {
    content = content instanceof $ ? content : $(content);
    this.$modal.find('.modal__content').empty().append(content);
    content.show();
  }

  open() {
    this.$body.addClass('locked');
    this.$backdrop.addClass('site-backdrop--active');
    this.$backdrop.append(this.$modal);
  }

  close(event) {
    if ($(event.target).hasClass('site-backdrop') || $(event.target).hasClass('modal-deactivate')) {
      this.$body.removeClass('locked');
      this.$modal.remove();
      this.$backdrop.removeClass('site-backdrop--active');
    }
  }
}