import $ from 'jquery';
import Vimeo from '@vimeo/player';

export default {
  init() {
      this.addEvents();
      this.modifyDOM();
  },

  addEvents() {
      $(document).on('click', '.js-play-video', this.onPlayRegular.bind(this));
      $(document).on('click', '.js-play-video-modal', this.onPlayModal.bind(this));
  },

  modifyDOM() {
  },

  onPlayRegular({ currentTarget }) {
    $(currentTarget).hide();
    this.onPlay(currentTarget)
  },
  onPlayModal({ currentTarget }) {
    this.onPlay(currentTarget)
  },

  onPlay(currentTarget) {
    const videoSelector = $(currentTarget).attr('data-video-id')

    if ($(`#${videoSelector}`).length) {
      let options = {
        playsline: false,
        autoplay: true
      };

      let player = new Vimeo(videoSelector, options);
      
      player.play();
    }
  },
};