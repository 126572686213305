import $ from 'jquery';
import Swiper from 'swiper';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    if ($('.tile-swiper').length) {
      var tileSwiper = new Swiper('.tile-swiper', {
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 16,
      });
    }
  },

};