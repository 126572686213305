export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    $('.dropdown-search').select2({
      ajax: {
        url: '/api/impersonate',
        data: function (params) {
          var query = {
            q: params.term || '',
            skip: params.page ? (params.page - 1) * 10 : 0,
          }

          return query;
        },
      },
      placeholder: 'Username',
    });
  },

};