import 'alpinejs';
import tileSwiper from '../components/tile-swiper';
import videoPlayer from '../components/video-player';
import modalDefault from '../components/modal-default';
import EmbeddedReport from '../components/embedded-report';
import impersonateForm from '../components/impersonate-form';
import splash from '../components/splash';
import $ from 'jquery';

export default {
  components: [
    tileSwiper,
    videoPlayer,
    modalDefault,
    impersonateForm,
	splash
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
  },

  modifyDOM() {
    $('.report-container').each((index, element) => {
      const report = new EmbeddedReport(element);
      report.load();
    });
  },
};
