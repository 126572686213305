export default (completeSiteMessageIds = '', hasSiteMessages, hasImpersonateError) => ({
  init() {
    const siteMessagesViewed = this.getCookie('siteMessagesViewed')
    const siteMessageIds = completeSiteMessageIds.split(',');
    const visibleSiteMessageIds = siteMessageIds.filter((id) => {
      return !siteMessagesViewed.includes(id);
    })

    if (!!hasSiteMessages && visibleSiteMessageIds.length > 0) {
      this.visibleSiteMessageIds = visibleSiteMessageIds;
      this.siteMessageSpace = visibleSiteMessageIds.length * 40;
    }
    if (!!hasImpersonateError) {
      this.menuOpen = 'impersonateMenu';
    }
  },
  menuOpen: '',
  siteMessageSpace: 0,
  visibleSiteMessageIds: [],
  onOpenMenu(id) {
    this.menuOpen = this.menuOpen === id ? '' : id;
  },
  onHideSiteMessage(id) {
    const siteMessagesViewed = this.getCookie('siteMessagesViewed')
    const updatedSiteMessagesViewed = [id, ...siteMessagesViewed];
    this.setCookie('siteMessagesViewed', updatedSiteMessagesViewed);
    const siteMessageIds = completeSiteMessageIds.split(',');
    const visibleSiteMessageIds = siteMessageIds.filter((id) => {
      return !updatedSiteMessagesViewed.includes(id);
    })
    this.visibleSiteMessageIds = visibleSiteMessageIds;
    this.siteMessageSpace = visibleSiteMessageIds.length * 40;
  },
  setCookie(name, value, exdays = 1) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + JSON.stringify(value) + ";" + expires + ";path=/";
  },
  getCookie(name) {
    let cname = name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) == 0) {
        return c.substring(cname.length, c.length) ? JSON.parse(c.substring(cname.length, c.length)) : "";
      }
    }
    return "";
  }
});
