import $ from 'jquery';
import Modal from './modal';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.activate-modal', this.open.bind(this));
  },

  showModal() {
    this.open();
    return false;
  },

  open({ currentTarget }) {
    const modalHolder = $(currentTarget).find('.modal-holder')
    const content = modalHolder.html();
    const image = modalHolder.attr('data-image');

    const modal = new Modal({
      content,
      image,
    });

    modal.open();
  },
  
};