import $ from 'jquery';
import Router from './utils/router';
import common from './routes/common';
import Alpine from 'alpinejs'
import header from './alpine/header';
import popupad from './alpine/popupad';
const routes = {
  common,
};

$(() => new Router(routes).loadEvents());

 
Alpine.data('header', header);
Alpine.data('popupad', popupad);
 
window.Alpine = Alpine
window.Alpine.start()