import $ from 'jquery';

export default {
	  init() {
      this.addEvents();
	  this.modifyDOM();
  },

  addEvents() {
      $(document).on('keyup', '#code1', this.onValidate.bind(this));
      $(document).on('keyup', '#code2', this.onValidate.bind(this));
	  $(document).on('keyup', '#code3', this.onValidate.bind(this));
	  $(document).on('keyup', '#code4', this.onValidate.bind(this));
  },
    modifyDOM() {
  },
        onValidate({ currentTarget }) {
            if (($('#code1').val().length > 0) && ($('#code2').val().length > 0) && ($('#code3').val().length > 0) && ($('#code4').val().length > 0)) {
                var element = document.getElementById("splash-btn");
                element.classList.remove("disabled");
            } else {
                var element = document.getElementById("splash-btn");
                element.classList.add("disabled");
            }
        }
};